export default class CustomModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.HSCode = '';
      this.OriginCountry = '';
      this.Percentage = '';
      this.DeparturePort = '';
    }
  }
  
  setModel(model){
    this.HSCode = model.HSCode;
    this.OriginCountry = model.OriginCountry;
    this.Percentage = model.Percentage;
    this.DeparturePort = model.DeparturePort;
  }
}