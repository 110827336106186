<template>
  <span>{{ selectedOptionText }}</span>
</template>

<script>
  export default {
    props: {
      value: {
        type: [String, Number, Object]
      },
      enum: {
        type: Object,
        required: true
      },
      ressourceName: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        selected: null
      };
    },
    methods: {
      getSelectedOptionText() {
        let self = this,
            property = self.enum.GetMatchingKey(self.selected);

        return self.$t(self.ressourceName + '.' + property);
      }
    },
    computed: {
      selectedOptionText() {
        let property = this.enum.GetMatchingKey(this.selected);
        return this.$t(this.ressourceName + '.' + property);
      }
    },
    watch: {
      value: {
        handler(newValue) {
          this.selected = newValue;
        },
        immediate: true
      },
      selected(newValue) {
        this.$emit('input', newValue);
      }
    }
  };
</script>
