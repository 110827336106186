class FileTypeEnumWeb {
  constructor() {
    this.Product = 0;
    this.Flyer = 1;
    this.PesticideDataSheet = 2;
    this.SecondaryImage = 3;
    this.ThirdImage = 4; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let fileTypeEnumWeb = new FileTypeEnumWeb();