export default class FileModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.FileId = '00000000-0000-0000-0000-000000000000';
      this.ProductId = '00000000-0000-0000-0000-000000000000';
      this.Name = '';
      this.StorageId = '';
      this.IsActive = false;
      this.Content = '';
      this.Type = 0;
      this.Url = '';
    }
  }
  
  setModel(model){
    this.FileId = model.FileId;
    this.ProductId = model.ProductId;
    this.Name = model.Name;
    this.StorageId = model.StorageId;
    this.IsActive = model.IsActive;
    this.Content = model.Content;
    this.Type = model.Type;
    this.Url = model.Url;
  }
}