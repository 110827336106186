<template>
  <div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="HSCode" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="HSCode">{{$t('product.hsCode')}}</label>
            <md-input type="text"
                      name="HSCode"
                      maxlength="25"
                      v-model="customs.HSCode"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="OriginCountry" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="OriginCountry">{{$t('product.originCountry')}}</label>
            <md-input type="text"
                      name="OriginCountry"
                      maxlength="100"
                      v-model="customs.OriginCountry"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="Percentage" rules="integer|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Percentage">{{$t('product.percentage')}}</label>
            <md-input type="text"
                      name="Percentage"
                      maxlength="10"
                      v-model="customs.Percentage"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="DeparturePort" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="DeparturePort">{{$t('product.departurePort')}}</label>
            <md-input type="text"
                      name="DeparturePort"
                      maxlength="100"
                      v-model="customs.DeparturePort"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customs: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
