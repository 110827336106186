<template>
  <div>
    <div class="md-layout-item md-layout md-gutter"
      v-bind:class="{ isRequired: areOmniFieldsRequired && storeInformation.Status == InternalStatusCodeEnum.None }">
      <ValidationObserver v-slot="{ validate, errors }" rules="required" class="md-layout-item md-small-size-100"
        tag="div" v-show="show([ProductViewModeEnum.LightWeb])">
        <div v-bind:class="{ hasError: errors['StoreStatus'] && errors['StoreStatus'].length > 0 }">
          <label for="StoreStatus">{{ $t('product.status') }}</label>
          <enum-select :enum="InternalStatusCodeEnum" v-model="storeInformation.Status"
            ressourceName="internalStatusCode" :defaultValue="InternalStatusCodeEnum.None" validationName="StoreStatus"
            :disabled="true" />
        </div>
      </ValidationObserver>
      <div class="md-layout-item md-small-size-100" v-show="show()"
        v-bind:class="{ isRequired: areOmniFieldsRequired && StringHelper.IsNullOrWhitespace(storeInformation.Price1) }">
        <ValidationProvider :name="normalizedBranchName + 'Price1'" rules="decimal|min_value:0.00|max_value:9999.99"
          v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Price1">{{ $t('product.price1') }}</label>
            <numeric-input :name="normalizedBranchName + 'Price1'" maxlength="9"
              v-model.number="storeInformation.Price1" :disabled="true" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider :name="normalizedBranchName + 'Price2'" rules="decimal|min_value:0.00|max_value:9999.99"
          v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Price2">{{ $t('product.price2') }}</label>
            <numeric-input :name="normalizedBranchName + 'Price2'" maxlength="9"
              v-model.number="storeInformation.Price2" :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider :name="normalizedBranchName + 'LowPrice'" rules="decimal|min_value:0.00|max_value:9999.99"
          v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="LowPrice">{{ $t('product.lowPrice') }}</label>
            <numeric-input :name="normalizedBranchName + 'LowPrice'" maxlength="9"
              v-model.number="storeInformation.LowPrice" :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100"
        v-bind:class="{ isRequired: areOmniFieldsRequired && StringHelper.IsNullOrWhitespace(storeInformation.Minimum) }">
        <ValidationProvider :name="normalizedBranchName + 'Minimum'" rules="decimal|min_value:0.00|max_value:9999.99"
          v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Minimum">{{ $t('product.minimum') }}</label>
            <numeric-input :name="normalizedBranchName + 'Minimum'" maxlength="9"
              v-model.number="storeInformation.Minimum" :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100"
        v-bind:class="{ isRequired: areOmniFieldsRequired && StringHelper.IsNullOrWhitespace(storeInformation.Maximum) }">
        <ValidationProvider :name="normalizedBranchName + 'Maximum'" rules="decimal|min_value:0.00|max_value:9999.99"
          v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Maximum">{{ $t('product.maximum') }}</label>
            <numeric-input :name="normalizedBranchName + 'Maximum'" maxlength="9"
              v-model.number="storeInformation.Maximum" :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider :name="normalizedBranchName + 'Quantity'" rules="integer" v-slot="{ errors, classes }"
          mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Quantity">{{ $t('product.quantity') }}</label>
            <numeric-input :name="normalizedBranchName + 'Quantity'" maxlength="9"
              v-model.number="storeInformation.Quantity" :disabled="true" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100" />
    </div>
  </div>
</template>

<script>
import { currencyEnumWeb } from '../../models/GeneratedModels/CurrencyEnumWeb.js';
import { internalStatusCodeEnumWeb } from '../../models/GeneratedModels/InternalStatusCodeEnumWeb.js';
import { companyBranchEnumWeb } from '../../models/GeneratedModels/CompanyBranchEnumWeb.js';
import StringHelper from '@/helpers/StringHelper.js';
import NumericInput from '@/components/NumericInput.vue';
import { productViewModeEnumWeb } from '@/models/GeneratedModels/ProductViewModeEnumWeb.js';

export default {
  props: {
    storeInformation: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    areOmniFieldsRequired: {
      type: Boolean,
      required: true
    },
    show: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      CurrencyEnum: currencyEnumWeb,
      InternalStatusCodeEnum: internalStatusCodeEnumWeb,
      CompanyBranchEnum: companyBranchEnumWeb,
      StringHelper: new StringHelper(),
      ProductViewModeEnum: productViewModeEnumWeb
    };
  },
  components: {
    NumericInput
  },
  computed: {
    branchName() {
      let name = this.CompanyBranchEnum.GetMatchingKey(this.storeInformation.Branch);
      return this.$t('companyBranch.' + name);
    },
    normalizedBranchName() {
      return this.branchName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s/g, '');
    }
  }
};
</script>
