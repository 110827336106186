<template>
  <div>
    <div class="md-layout-item md-layout md-gutter" v-show="show([ProductViewModeEnum.LightWeb])">
      <div class="md-layout-item md-small-size-100 width100">
        <ValidationProvider name="omniDescription" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="omniDescription">{{$t('product.omniDescription')}}</label>
            <md-input type="text"
                      name="omniDescription"
                      maxlength="30"
                      v-model="generalStoreInformation.GeneralStoreDescription"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100 width100">
        <ValidationProvider name="omniAdditionalDescription" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="omniAdditionalDescription">{{$t('product.storeDescription')}}</label>
            <md-input type="text"
                      name="omniAdditionalDescription"
                      maxlength="30"
                      v-model="generalStoreInformation.OmniAdditionalDescription"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="OnScreenMessage" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="OnScreenMessage">{{$t('product.onScreenMessage')}}</label>
            <md-input type="text"
                      name="OnScreenMessage"
                      maxlength="200"
                      v-model="generalStoreInformation.OnScreenMessage"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100" v-bind:class="{ isRequired : areOmniFieldsRequired && StringHelper.IsNullOrWhitespace(generalStoreInformation.Alpha1) }">
        <ValidationProvider name="Alpha1" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Alpha1">{{$t('product.alpha1')}}</label>
            <md-input type="text"
                      name="Alpha1"
                      maxlength="30"
                      v-model="generalStoreInformation.Alpha1"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="OnBillMessage" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="OnBillMessage">{{$t('product.onBillMessage')}}</label>
            <md-input type="text"
                      name="OnBillMessage"
                      maxlength="200"
                      v-model="generalStoreInformation.OnBillMessage"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100" v-bind:class="{ isRequired : areOmniFieldsRequired && StringHelper.IsNullOrWhitespace(generalStoreInformation.Search1) }">
        <ValidationProvider name="Search1" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Search1">{{$t('product.search1')}}</label>
            <md-input type="text"
                      name="Search1"
                      maxlength="15"
                      v-model="generalStoreInformation.Search1"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100" v-bind:class="{ isRequired : areOmniFieldsRequired && generalStoreInformation.SalesUnit == SalesUnitEnum.None }">
        <ValidationObserver v-slot="{ validate, errors }" rules="required" class="md-layout-item md-small-size-100" tag="div">
          <div v-bind:class="{ hasError: errors['SalesUnit'] && errors['SalesUnit'].length > 0 }">
            <label for="SalesUnit">{{$t('product.salesUnit')}}</label>
            <enum-select
              :enum="SalesUnitEnum"
              v-model="generalStoreInformation.SalesUnit"
              ressourceName="salesUnit"
              :defaultValue="SalesUnitEnum.None"
              validationName="SalesUnit"
              :disabled="disabled" />
          </div>
        </ValidationObserver>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="Search2" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Search2">{{$t('product.search2')}}</label>
            <md-input type="text"
                      name="Search2"
                      maxlength="15"
                      v-model="generalStoreInformation.Search2"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="StoreTag" v-slot="{ errors }" mode="lazy" slim>
          <md-switch v-model="generalStoreInformation.Tag" :disabled="disabled">{{$t('product.tag')}}</md-switch>
          <span class="md-error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="Search3" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Search3">{{$t('product.search3')}}</label>
            <md-input type="text"
                      name="Search3"
                      maxlength="15"
                      v-model="generalStoreInformation.Search3"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="ProductUnit" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="ProductUnit">{{$t('product.productUnit')}}</label>
            <md-input type="text"
                      name="ProductUnit"
                      maxlength="15"
                      v-model="generalStoreInformation.ProductUnit"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="Search4" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Search4">{{$t('product.search4')}}</label>
            <md-input type="text"
                      name="Search4"
                      maxlength="15"
                      v-model="generalStoreInformation.Search4"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter" v-show="show()">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="BillInformation" v-slot="{ errors }" mode="lazy" slim>
          <md-switch v-model="generalStoreInformation.BillInformation" :disabled="disabled">{{$t('product.billInformation')}}</md-switch>
          <span class="md-error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="StoreFamily" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="StoreFamily">{{$t('product.family')}}</label>
            <md-input type="text"
                      name="StoreFamily"
                      v-model="generalStoreInformation.Family"
                      maxlength="15"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import { salesUnitEnumWeb } from '../../models/GeneratedModels/SalesUnitEnumWeb.js';
import StringHelper from '@/helpers/StringHelper.js';
import { productViewModeEnumWeb } from '@/models/GeneratedModels/ProductViewModeEnumWeb.js';

export default {
  props: {
    generalStoreInformation: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    areOmniFieldsRequired: {
      type: Boolean,
      required: true
    },
    show: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      SalesUnitEnum: salesUnitEnumWeb,
      StringHelper: new StringHelper(),
      ProductViewModeEnum: productViewModeEnumWeb
    };
  }
};
</script>
