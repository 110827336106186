<template>
  <div>
    <div class="md-layout-item md-layout md-gutter">
      <ValidationObserver v-slot="{ validate, errors }" rules="required" class="md-layout-item md-small-size-100" tag="div">
        <div v-bind:class="{ hasError: errors['Currency'] && errors['Currency'].length > 0 }">
          <label for="Currency">{{$t('product.currency')}}</label>
          <enum-select :enum="CurrencyEnum"
                       v-model="costings.Currency"
                       ressourceName="currency"
                       :defaultValue="CurrencyEnum.None"
                       validationName="Currency"
                       :disabled="disabled" />
        </div>
      </ValidationObserver>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="PurchaseDiscount" rules="decimal|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="PurchaseDiscount">{{$t('product.purchaseDiscount')}}</label>
            <numeric-input name="PurchaseDiscount"
                           maxlength="9"
                           v-model.number="costings.PurchaseDiscount"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="Quantity" rules="decimal|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Quantity">{{$t('product.quantity')}}</label>
            <numeric-input name="Quantity"
                           maxlength="9"
                           v-model.number="costings.Quantity"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100" v-bind:class="{ isRequired : areOmniFieldsRequired && StringHelper.IsNullOrWhitespace(costings.LaferteCost) }">
        <ValidationProvider name="LaferteCost" rules="decimal|min_value:0.01|max_value:9999.99" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="LaferteCost">{{$t('product.laferteCost')}}</label>
            <numeric-input name="LaferteCost"
                           maxlength="9"
                           v-model.number="costings.LaferteCost"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="EcologicalFeesQc" rules="decimal|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="EcologicalFeesQc">{{$t('product.ecologicalFeesQc')}}</label>
            <numeric-input name="EcologicalFeesQc"
                           maxlength="9"
                           v-model.number="costings.EcologicalFeesQc"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="EcologicalFeesOnt" rules="decimal|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="EcologicalFeesOnt">{{$t('product.ecologicalFeesOnt')}}</label>
            <numeric-input name="EcologicalFeesOnt"
                           maxlength="9"
                           v-model.number="costings.EcologicalFeesOnt"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="EcologicalFeesOthers" rules="decimal|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="EcologicalFeesOthers">{{$t('product.ecologicalFeesOthers')}}</label>
            <numeric-input name="EcologicalFeesOthers"
                           maxlength="9"
                           v-model.number="costings.EcologicalFeesOthers"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="SuggestedRetailPrice" rules="decimal|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="SuggestedRetailPrice">{{$t('product.suggestedRetailPrice')}}</label>
            <numeric-input name="SuggestedRetailPrice"
                           maxlength="9"
                           v-model.number="costings.SuggestedRetailPrice"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
  import { currencyEnumWeb } from '../../models/GeneratedModels/CurrencyEnumWeb.js';
  import StringHelper from '@/helpers/StringHelper.js';
  import NumericInput from '@/components/NumericInput.vue';

  export default {
    props: {
      costings: {
        type: Object,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      areOmniFieldsRequired: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        CurrencyEnum: currencyEnumWeb,
        StringHelper: new StringHelper()
      };
    },
    components: {
      NumericInput
    }
  };
</script>
