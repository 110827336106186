import ProductModel from '@/models/GeneratedModels/ProductModel.js';

export default class Product extends ProductModel {
  constructor(model) {
    super(model);

    if (!model) {
      this.Quantities.UnitPerBox = 1;
      this.Quantities.MinimalQuantityToOrder = 1;
      this.GeneralStoreInformation.Tag = true;
    }
  }
}
