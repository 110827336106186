export default class ProviderModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.ProviderId = '00000000-0000-0000-0000-000000000000';
      this.Number = '';
      this.Name = '';
      this.Address1 = '';
      this.Address2 = '';
      this.Address3 = '';
      this.Status = '';
      this.PostalCode = '';
      this.Search = '';
    }
  }
  
  setModel(model){
    this.ProviderId = model.ProviderId;
    this.Number = model.Number;
    this.Name = model.Name;
    this.Address1 = model.Address1;
    this.Address2 = model.Address2;
    this.Address3 = model.Address3;
    this.Status = model.Status;
    this.PostalCode = model.PostalCode;
    this.Search = model.Search;
  }
}