export default class CostingModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.Currency = 0;
      this.PurchaseDiscount = null;
      this.Quantity = null;
      this.LaferteCost = null;
      this.EcologicalFeesQc = null;
      this.EcologicalFeesOnt = null;
      this.EcologicalFeesOthers = null;
      this.SuggestedRetailPrice = null;
    }
  }
  
  setModel(model){
    this.Currency = model.Currency;
    this.PurchaseDiscount = model.PurchaseDiscount;
    this.Quantity = model.Quantity;
    this.LaferteCost = model.LaferteCost;
    this.EcologicalFeesQc = model.EcologicalFeesQc;
    this.EcologicalFeesOnt = model.EcologicalFeesOnt;
    this.EcologicalFeesOthers = model.EcologicalFeesOthers;
    this.SuggestedRetailPrice = model.SuggestedRetailPrice;
  }
}