<template>
  <md-input type="number"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            :name="name"
            :maxlength="maxlength"
            v-model.number="currentValue"
            :disabled="disabled" />
</template>

<script>
  import StringHelper from '@/helpers/StringHelper.js';

  export default {
    props: {
      value: {
        required: true
      },
      name: {
        type: String,
        default: 'NumericInput'
      },
      maxlength: {
        type: String,
        default: '9'
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      currentValue: '',
      StringHelper: new StringHelper()
    }),
    created() {
      this.currentValue = this.value;
    },
    watch: {
      currentValue(val) {
        this.$emit('input', val);
      },
      value: {
        handler(newValue) {
          this.currentValue = this.StringHelper.IsNullOrWhitespace(newValue) ? null : newValue;
        },
        immediate: true
      }
    }
  };
</script>


