export default class DimensionModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.Width = '';
      this.Height = '';
      this.Depth = '';
      this.Diameter = '';
      this.Weight = '';
      this.Volume = '';
    }
  }
  
  setModel(model){
    this.Width = model.Width;
    this.Height = model.Height;
    this.Depth = model.Depth;
    this.Diameter = model.Diameter;
    this.Weight = model.Weight;
    this.Volume = model.Volume;
  }
}