class CompanyBranchEnumWeb {
  constructor() {
    this.None = 0;
    this.Drummondville = 1;
    this.StHyacinthe = 2;
    this.ActonVale = 3;
    this.Longueuil = 4; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let companyBranchEnumWeb = new CompanyBranchEnumWeb();