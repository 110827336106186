export default class GeneralStoreInformationModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.BillInformation = false;
      this.GeneralStoreDescription = '';
      this.OmniAdditionalDescription = '';
      this.OnScreenMessage = '';
      this.Alpha1 = '';
      this.OnBillMessage = '';
      this.Search1 = '';
      this.Search2 = '';
      this.Search3 = '';
      this.Search4 = '';
      this.SalesUnit = 0;
      this.Tag = false;
      this.Family = '';
      this.ProductUnit = '';
    }
  }
  
  setModel(model){
    this.BillInformation = model.BillInformation;
    this.GeneralStoreDescription = model.GeneralStoreDescription;
    this.OmniAdditionalDescription = model.OmniAdditionalDescription;
    this.OnScreenMessage = model.OnScreenMessage;
    this.Alpha1 = model.Alpha1;
    this.OnBillMessage = model.OnBillMessage;
    this.Search1 = model.Search1;
    this.Search2 = model.Search2;
    this.Search3 = model.Search3;
    this.Search4 = model.Search4;
    this.SalesUnit = model.SalesUnit;
    this.Tag = model.Tag;
    this.Family = model.Family;
    this.ProductUnit = model.ProductUnit;
  }
}