class SeasonCodeEnumWeb {
  constructor() {
    this.None = 0;
    this.Autumn = 1;
    this.Winter = 2;
    this.Spring = 3;
    this.Summer = 50; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let seasonCodeEnumWeb = new SeasonCodeEnumWeb();