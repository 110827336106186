import FileModel from './FileModel.js';
export default class MiscellaneousModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.ExpirationDate = false;
      this.IsFreezingProduct = false;
      this.Pesticide = false;
      this.PesticideKind = '';
      this.DangerousProduct = false;
      this.CertifiedProduct = false;
      this.UNCode = '';
      this.DataSheet = new FileModel();
      this.MeasureUnit = '';
      this.Volume = '';
    }
  }
  
  setModel(model){
    this.ExpirationDate = model.ExpirationDate;
    this.IsFreezingProduct = model.IsFreezingProduct;
    this.Pesticide = model.Pesticide;
    this.PesticideKind = model.PesticideKind;
    this.DangerousProduct = model.DangerousProduct;
    this.CertifiedProduct = model.CertifiedProduct;
    this.UNCode = model.UNCode;
    this.DataSheet.setModel(model.DataSheet);
    this.MeasureUnit = model.MeasureUnit;
    this.Volume = model.Volume;
  }
}