<template>
  <div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="ContainerQuantity" rules="integer|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="ContainerQuantity">{{$t('product.containerQuantity')}}</label>
            <numeric-input name="ContainerQuantity"
                           maxlength="9"
                           v-model.number="quantities.ContainerQuantity"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="PalletQuantity" rules="integer|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="PalletQuantity">{{$t('product.palletQuantity')}}</label>
            <numeric-input name="PalletQuantity"
                           maxlength="9"
                           v-model.number="quantities.PalletQuantity"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="MasterPackQuantity" rules="integer|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="MasterPackQuantity">{{$t('product.masterPackQuantity')}}</label>
            <numeric-input name="MasterPackQuantity"
                           maxlength="9"
                           v-model.number="quantities.MasterPackQuantity"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="MasterPackBarCode" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="MasterPackBarCode">{{$t('product.masterPackBarCode')}}</label>
            <md-input type="text"
                      name="MasterPackBarCode"
                      maxlength="50"
                      v-model="quantities.MasterPackBarCode"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="InnerPackQuantity" rules="integer|min_value:0" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="InnerPackQuantity">{{$t('product.innerPackQuantity')}}</label>
            <numeric-input name="InnerPackQuantity"
                           maxlength="9"
                           v-model.number="quantities.InnerPackQuantity"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="InnerPackBarCode" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="InnerPackBarCode">{{$t('product.innerPackBarCode')}}</label>
            <md-input type="text"
                      name="InnerPackBarCode"
                      maxlength="50"
                      v-model="quantities.InnerPackBarCode"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="UnitPerBox" rules="required|integer|min_value:0|max_value:99999" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="UnitPerBox">{{$t('product.unitPerBox')}}</label>
            <numeric-input name="UnitPerBox"
                           maxlength="9"
                           v-model.number="quantities.UnitPerBox"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="MinimalQuantityToOrder" rules="required|integer|min_value:0|max_value:99999" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="MinimalQuantityToOrder">{{$t('product.minimalQuantityToOrder')}}</label>
            <numeric-input name="MinimalQuantityToOrder"
                           maxlength="9"
                           v-model.number="quantities.MinimalQuantityToOrder"
                           :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
  import NumericInput from '@/components/NumericInput.vue';

  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      quantities: {
        type: Object,
        required: true
      }
    },
    components: {
      NumericInput
    }
  };
</script>
