class ProductViewModeEnumWeb {
  constructor() {
    this.None = 0;
    this.LightWeb = 1; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let productViewModeEnumWeb = new ProductViewModeEnumWeb();