<template>
  <div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="ExpirationDate" v-slot="{ errors }" mode="lazy" slim>
          <md-switch v-model="miscellaneous.ExpirationDate" :disabled="disabled">{{$t('product.expirationDate')}}</md-switch>
          <span class="md-error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="Pesticide" v-slot="{ errors }" mode="lazy" slim>
          <md-switch v-model="miscellaneous.Pesticide" :disabled="disabled">{{$t('product.pesticide')}}</md-switch>
          <span class="md-error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="CertifiedProduct" v-slot="{ errors }" mode="lazy" slim>
          <md-switch v-model="miscellaneous.CertifiedProduct" :disabled="disabled">{{$t('product.certifiedProduct')}}</md-switch>
          <span class="md-error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100 margin0">
        <ValidationProvider name="PesticideKind" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="PesticideKind">{{$t('product.pesticideKind')}}</label>
            <md-input type="text"
                      name="PesticideKind"
                      maxlength="100"
                      v-model="miscellaneous.PesticideKind"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="FreezingProduct" v-slot="{ errors }" mode="lazy" slim>
          <md-switch v-model="miscellaneous.IsFreezingProduct" :disabled="disabled">{{$t('product.freezingProduct')}}</md-switch>
          <span class="md-error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider name="DangerousProduct" v-slot="{ errors }" mode="lazy" slim>
          <md-switch v-model="miscellaneous.DangerousProduct" :disabled="disabled">{{$t('product.dangerousProduct')}}</md-switch>
          <span class="md-error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100"></div>
      <div class="md-layout-item md-small-size-100 margin0">
        <ValidationProvider name="UNCode" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="UNCode">{{$t('product.unCode')}}</label>
            <md-input type="text"
                      name="UNCode"
                      maxlength="100"
                      v-model="miscellaneous.UNCode"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100 width100">
        <ValidationProvider name="MeasureUnit" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="MeasureUnit">{{$t('product.measureUnit')}}</label>
            <md-input type="text"
                      name="MeasureUnit"
                      maxlength="25"
                      v-model="miscellaneous.MeasureUnit"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
        <ValidationProvider name="Volume" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Volume">{{$t('product.volume')}}</label>
            <md-input type="text"
                      name="Volume"
                      maxlength="3"
                      v-model="miscellaneous.Volume"
                      :disabled="disabled" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <ValidationProvider rules="size:1000" name="DataSheet" ref="pesticideFileProvider" v-slot="{ errors, classes, validate }" mode="passive" slim>
        <md-field v-bind:class="classes">
          <md-file type="file"
                   name="DataSheet"
                   :placeholder="pesticideFilePlaceholder"
                   @change="uploadDataSheet" />
          <span class="md-error">{{ errors[0] }}</span>
          <a v-if="hasDataSheet" target="_blank" v-bind:href="miscellaneous.DataSheet.Url">{{$t('form.downloadFile')}}</a>
        </md-field>
      </ValidationProvider>
      <div>
        <span style="color: #ff1744;" v-if="hasTemporaryDataSheet">*{{$t('product.temporaryFile')}}</span>
      </div>
      <p>{{ $t('product.webDataSheetDescription') }}</p>
    </div>
  </div>
</template>

<script>

  import { fileTypeEnumWeb } from '@/models/GeneratedModels/FileTypeEnumWeb.js';
  import FileModel from '@/models/GeneratedModels/FileModel.js';

  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      miscellaneous: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      FileTypeEnum: fileTypeEnumWeb,
      dataSheetValidationRequired: true,
      hasReceivedDataSheet: false
    }),
    computed: {
      pesticideFilePlaceholder() {
        if (this.miscellaneous.DataSheet) {
          return this.miscellaneous.DataSheet.Name ? this.miscellaneous.DataSheet.Name : this.$t('form.insertFile');
        }
        else {
          return this.$t('form.insertFile');
        }
      },
      hasTemporaryDataSheet() {
        return this.miscellaneous.DataSheet && !this.miscellaneous.DataSheet.StorageId && !this.hasReceivedDataSheet;
      },
      hasDataSheet() {
        return this.miscellaneous.DataSheet && this.miscellaneous.DataSheet.StorageId && !this.hasReceivedDataSheet;
      }
    },
    methods: {
      async uploadDataSheet(e) {
        var self = this;
        self.dataSheetValidationRequired = true;
        self.hasReceivedDataSheet = true;
        const { valid } = await this.$refs.pesticideFileProvider.validate(e);
        if (valid) {
          const dataSheet = e.target.files[0];
          const reader = new FileReader();
          if (!self.miscellaneous.DataSheet) {
            self.miscellaneous.DataSheet = new FileModel();
          }
          self.miscellaneous.DataSheet.Name = dataSheet.name;
          self.miscellaneous.DataSheet.Type = self.FileTypeEnum.PesticideDataSheet;
          self.miscellaneous.DataSheet.IsActive = true;
          reader.readAsDataURL(dataSheet);
          reader.onload = e => {
            self.miscellaneous.DataSheet.Content = e.target.result.split(',')[1];
          };
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .md-layout-item.margin0 .md-theme-default {
    margin: 0;
  }
</style>
