export default class MissingFieldModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.Field = '';
      this.Locale = '';
    }
  }
  
  setModel(model){
    this.Field = model.Field;
    this.Locale = model.Locale;
  }
}