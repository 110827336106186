<template>
  <div>
    <div class="md-layout-item md-layout">
      <ValidationProvider name="Width" v-slot="{ errors, classes }" mode="lazy" slim>
        <md-field v-bind:class="classes">
          <label for="Width">{{$t('product.width')}}</label>
          <md-input type="text"
                    name="Width"
                    maxlength="25"
                    v-model="dimensions.Width"
                    :disabled="disabled" />
          <span class="md-error">{{ errors[0] }}</span>
        </md-field>
      </ValidationProvider>
    </div>
    <div class="md-layout-item md-layout">
      <ValidationProvider name="Height" v-slot="{ errors, classes }" mode="lazy" slim>
        <md-field v-bind:class="classes">
          <label for="Height">{{$t('product.height')}}</label>
          <md-input type="text"
                    name="Height"
                    maxlength="25"
                    v-model="dimensions.Height"
                    :disabled="disabled" />
          <span class="md-error">{{ errors[0] }}</span>
        </md-field>
      </ValidationProvider>
    </div>
    <div class="md-layout-item md-layout">
      <ValidationProvider name="Depth" v-slot="{ errors, classes }" mode="lazy" slim>
        <md-field v-bind:class="classes">
          <label for="Depth">{{$t('product.depth')}}</label>
          <md-input type="text"
                    name="Depth"
                    maxlength="100"
                    v-model="dimensions.Depth"
                    :disabled="disabled" />
          <span class="md-error">{{ errors[0] }}</span>
        </md-field>
      </ValidationProvider>
    </div>
    <div class="md-layout-item md-layout">
      <ValidationProvider name="Diameter" v-slot="{ errors, classes }" mode="lazy" slim>
        <md-field v-bind:class="classes">
          <label for="Diameter">{{$t('product.diameter')}}</label>
          <md-input type="text"
                    name="Diameter"
                    maxlength="25"
                    v-model="dimensions.Diameter"
                    :disabled="disabled" />
          <span class="md-error">{{ errors[0] }}</span>
        </md-field>
      </ValidationProvider>
    </div>
    <div class="md-layout-item md-layout">
      <ValidationProvider name="Weight" v-slot="{ errors, classes }" mode="lazy" slim>
        <md-field v-bind:class="classes">
          <label for="Weight">{{$t('product.weight')}}</label>
          <md-input type="text"
                    name="Weight"
                    maxlength="25"
                    v-model="dimensions.Weight"
                    :disabled="disabled" />
          <span class="md-error">{{ errors[0] }}</span>
        </md-field>
      </ValidationProvider>
    </div>
    <div class="md-layout-item md-layout">
      <ValidationProvider name="Volume" v-slot="{ errors, classes }" mode="lazy" slim>
        <md-field v-bind:class="classes">
          <label for="Volume">{{$t('product.volume')}}</label>
          <md-input type="text"
                    name="Volume"
                    maxlength="25"
                    v-model="dimensions.Volume"
                    :disabled="disabled" />
          <span class="md-error">{{ errors[0] }}</span>
        </md-field>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import { currencyEnumWeb } from '../../models/GeneratedModels/CurrencyEnumWeb.js';

export default {
  props: {
    dimensions: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      CurrencyEnum: currencyEnumWeb
    };
  }
};
</script>
