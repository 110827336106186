class InternalStatusCodeEnumWeb {
  constructor() {
    this.None = 0;
    this.Regular = 1;
    this.ExclusiveItemToBranch = 2;
    this.NewItemFirstYear = 3;
    this.Discontinued = 4;
    this.DiscontinuedNotInInventory = 5;
    this.Promotional = 6;
    this.OneShotDeal = 7;
    this.OffSeasonItem = 8;
    this.StoppedForBilling = 9; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let internalStatusCodeEnumWeb = new InternalStatusCodeEnumWeb();