class CurrencyEnumWeb {
  constructor() {
    this.None = 0;
    this.CAN = 1;
    this.US = 2;
    this.EURO = 3; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let currencyEnumWeb = new CurrencyEnumWeb();