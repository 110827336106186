class SalesUnitEnumWeb {
  constructor() {
    this.None = 0;
    this.CH = 1;
    this.BTE = 2;
    this.PL = 3;
    this.PQT = 4;
    this.ENS = 5;
    this.M = 6;
    this.MIL = 7;
    this.PI = 8;
    this.RLX = 9;
    this.HEU = 10;
    this.LBS = 11;
    this.PAL = 12;
    this.PC = 13;
    this.PO = 14;
    this.PAI = 15;
    this.SAC = 16;
    this.VC = 17;
    this.VG = 18; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let salesUnitEnumWeb = new SalesUnitEnumWeb();