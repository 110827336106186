export default class StoreInformationModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.Branch = null;
      this.Status = 0;
      this.Price1 = null;
      this.Price2 = null;
      this.LowPrice = null;
      this.Minimum = null;
      this.Maximum = null;
      this.Quantity = null;
    }
  }
  
  setModel(model){
    this.Branch = model.Branch;
    this.Status = model.Status;
    this.Price1 = model.Price1;
    this.Price2 = model.Price2;
    this.LowPrice = model.LowPrice;
    this.Minimum = model.Minimum;
    this.Maximum = model.Maximum;
    this.Quantity = model.Quantity;
  }
}