export default class QuantityModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.PalletQuantity = null;
      this.ContainerQuantity = null;
      this.MasterPackQuantity = null;
      this.MasterPackBarCode = '';
      this.InnerPackQuantity = null;
      this.InnerPackBarCode = '';
      this.UnitPerBox = 0;
      this.MinimalQuantityToOrder = 0;
    }
  }
  
  setModel(model){
    this.PalletQuantity = model.PalletQuantity;
    this.ContainerQuantity = model.ContainerQuantity;
    this.MasterPackQuantity = model.MasterPackQuantity;
    this.MasterPackBarCode = model.MasterPackBarCode;
    this.InnerPackQuantity = model.InnerPackQuantity;
    this.InnerPackBarCode = model.InnerPackBarCode;
    this.UnitPerBox = model.UnitPerBox;
    this.MinimalQuantityToOrder = model.MinimalQuantityToOrder;
  }
}