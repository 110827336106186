<template>
  <ValidationObserver v-slot="{ errors }" ref="productLocaleObserver" tag="div">

    <div class="md-layout-item md-layout md-gutter blockElement" v-show="show(locale === 'fr-CA' ? [ProductViewModeEnum.LightWeb] : null)">
      <div class="md-layout-item md-small-size-100" v-bind:class="{ isRequired : areWebFieldsRequired && StringHelper.IsNullOrWhitespace(productLocale.Description) && productLocale.Locale == 'fr-CA' }">
        <ValidationProvider :name="getValidationName('Description')" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Description">{{$t('product.descriptiveTitle')}}</label>
            <md-input type="text"
                         name="Description"
                         maxlength="250"
                         v-model="productLocale.Description" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter blockElement" v-show="show(locale === 'fr-CA' ? [ProductViewModeEnum.LightWeb] : null)">
      <div class="md-layout-item md-small-size-100">
        <ValidationProvider :name="getValidationName('AdditionalInformation')" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="AdditionalInformation">{{$t('product.fullDetailedDescription')}}</label>
            <md-textarea type="text"
                      name="AdditionalInformation"
                      maxlength="2000"
                      v-model="productLocale.AdditionalInformation" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100" v-show="show(locale === 'fr-CA' ? [ProductViewModeEnum.LightWeb] : null)">
        <ValidationProvider :name="getValidationName('Brand')" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Brand">{{$t('product.brand')}}</label>
            <md-input type="text"
                      name="Brand"
                      maxlength="50"
                      v-model="productLocale.Brand" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100" v-show="show(locale === 'fr-CA' ? [ProductViewModeEnum.LightWeb] : null)">
        <ValidationProvider :name="getValidationName('Collection')" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Collection">{{$t('product.collection')}}</label>
            <md-input type="text"
                      name="Collection"
                      maxlength="100"
                      v-model="productLocale.Collection" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100" v-show="show(locale === 'fr-CA' ? [ProductViewModeEnum.LightWeb] : null)">
        <ValidationProvider :name="getValidationName('MadeFrom')" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="MadeFrom">{{$t('product.madeFrom')}}</label>
            <md-input type="text"
                      name="MadeFrom"
                      maxlength="50"
                      v-model="productLocale.MadeFrom" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100" v-show="show(locale === 'fr-CA' ? [ProductViewModeEnum.LightWeb] : null)">
        <ValidationProvider :name="getValidationName('Dimension')" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Dimension">{{$t('product.dimension')}}</label>
            <md-input type="text"
                      name="Dimension"
                      maxlength="100"
                      v-model="productLocale.Dimension" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="md-layout-item md-layout md-gutter">
      <div class="md-layout-item md-small-size-100" v-show="show(locale === 'fr-CA' ? [ProductViewModeEnum.LightWeb] : null)">
        <ValidationProvider :name="getValidationName('Color')" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Color">{{$t('product.color')}}</label>
            <md-input type="text"
                      name="Color"
                      maxlength="100"
                      v-model="productLocale.Color" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item md-small-size-100" v-show="show(locale === 'fr-CA' ? [ProductViewModeEnum.LightWeb] : null)">
        <ValidationProvider :name="getValidationName('Format')" v-slot="{ errors, classes }" mode="lazy" slim>
          <md-field v-bind:class="classes">
            <label for="Format">{{$t('product.format')}}</label>
            <md-input type="text"
                      name="Format"
                      maxlength="50"
                      v-model="productLocale.Format" />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
  import StringHelper from '@/helpers/StringHelper.js';
import { productViewModeEnumWeb } from '@/models/GeneratedModels/ProductViewModeEnumWeb.js';

  export default {
    props: {
      locale: {
        type: String,
        default: null
      },
      productLocale: {
        type: Object,
        required: true
      },
      areWebFieldsRequired: {
        type: Boolean,
        required: true
      },
      hasError: {
        type: Boolean,
        required: true
      },
      show: {
        type: Function,
        default: () => true
      }
    },
    data() {
      return {
        productLocaleObserver: null,
        ProductViewModeEnum: productViewModeEnumWeb,
        StringHelper: new StringHelper()
      };
    },
    mounted() {
      this.productLocaleObserver = this.$refs.productLocaleObserver;
    },
    methods: {
      getValidationName(baseValidationName) {
        return baseValidationName + '-' + this.productLocale.Locale;
      }
    },
    computed: {
      isInvalid() {
        return this.productLocaleObserver ? this.productLocaleObserver.flags.invalid : false;
      }
    },
    watch: {
      'isInvalid': {
        handler(val) {
          this.$emit('update:hasError', val);
        }
      }
    }
  };
</script>
