export default class ProductLocaleModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.LocaleId = '00000000-0000-0000-0000-000000000000';
      this.Locale = '';
      this.Brand = '';
      this.Collection = '';
      this.Color = '';
      this.Description = '';
      this.Dimension = '';
      this.Format = '';
      this.AdditionalInformation = '';
      this.MadeFrom = '';
    }
  }
  
  setModel(model){
    this.LocaleId = model.LocaleId;
    this.Locale = model.Locale;
    this.Brand = model.Brand;
    this.Collection = model.Collection;
    this.Color = model.Color;
    this.Description = model.Description;
    this.Dimension = model.Dimension;
    this.Format = model.Format;
    this.AdditionalInformation = model.AdditionalInformation;
    this.MadeFrom = model.MadeFrom;
  }
}