class PublicationStatusEnum {
  constructor() {
    this.MissingInformation = 0;
    this.ReadyToPublish = 1;
    this.Published = 2;
    this.Deleted = 3;
    this.NoPlannedPublish = 4; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let publicationStatusEnum = new PublicationStatusEnum();